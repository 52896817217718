<template>
  <div class="card__wrapper">
    <div class="card__title title-second">Подтверждено успешно</div>
    <div class="card__description">Ваша почта подтверждена</div>
    <div class="card__form small">
      <router-link to="/login/customer" tag="button" class="btn btn-red">Войти</router-link>
      <!--          <a href="https://mait-nauka.ru/" class="btn btn-red">Вернуться на главную</a>-->
    </div>
  </div>
</template>

<script>

import store from '@/store'

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch('PERSONAL_SEND_EMAIL_CONFIRM', to.query).finally(() => {
      next()
    })
  },
}
</script>
